export const useResourcesData = () => {
  const HEADER = {
    title: "Resources",
  }

  const TABS = [
    {
      label: "Practice Resources",
      href: "#practice",
    },
    {
      label: "Educational Support Resources",
      href: "#educational",
    },
  ]

  const TOPIC = {
    header: "Resources for your Practice and Patient Support",
    topic:
      "A range of PALFORZIA educational materials is available for your practice and patients.",
  }

  const TOPIC_B = {
    topic:
      "Prescribers, healthcare settings, and patients must be enrolled in the PALFORZIA REMS Program before a patient can be prescribed PALFORZIA and enrolled in PALFORZIA Pathway.",
  }

  const CARD_A = {
    header: "Practice resources",
    cards: [
      {
        image: "WebIcon",
        alt: "PALFORZIA QuickEnroll Electronic Patient Prescription Enrollment thumbnail",
        body: "PALFORZIA QuickEnroll Electronic Patient Prescription Enrollment",
        cta: {
          url: "https://palforziaquickenroll.com/",
          ariaLabel: "PALFORZIA QuickEnroll Electronic Patient Prescription Enrollment",
          label: "Access the Portal",
          showFileIcon: false,
        },
      },
      {
        image: "CardA1",
        alt: "Prescription and Enrollment Form thumbnail",
        body: "Prescription and Enrollment Form",
        cta: {
          url: "/enrollment.pdf",
          ariaLabel: "Prescription and Enrollment Form",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA1",
        alt: "Prescription and Enrollment Form in Spanish thumbnail",
        body: "Prescription and Enrollment Form (Spanish)",
        cta: {
          url: "/enrollment_spanish.pdf",
          ariaLabel: "Prescription and Enrollment Form Spanish",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "WebIcon",
        alt: "Palforzia Up-dose Shipment Request Portal thumbnail",
        body: "Palforzia Up-dose Shipment Request Portal",
        cta: {
          url: "https://palforziaupdose.com/",
          ariaLabel: "Palforzia Up-dose Shipment Request Fax Form",
          label: "Access the Portal",
          showFileIcon: false,
        },
      },
      {
        image: "UpDoseIcon",
        alt: "Palforzia Up-dose Shipment Request Fax Form thumbnail",
        body: "Palforzia Up-dose Shipment Request Fax Form",
        cta: {
          url: "/up-dose-form.pdf",
          ariaLabel: "Palforzia Up-dose Shipment Request Fax Form",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "ToddlerLBIcon",
        alt: "Palforzia Toddler Indication Brochure thumbnail",
        body: "Palforzia Toddler Indication Brochure",
        cta: {
          url: "/palforzia-toddler-indication-brochure.pdf",
          ariaLabel: "Palforzia Toddler Indication Brochure",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "ProcessResourceIcon",
        alt: "Palforzia HUB & SP Process Resource thumbnail",
        body: "Palforzia HUB & SP Process Resource",
        cta: {
          url: "/palforzia-HUB-SP-process-resource.pdf",
          ariaLabel: "Palforzia HUB & SP Process Resource",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA9",
        alt: "Practice Implementation Checklist thumbnail",
        body: "Practice Implementation Checklist",
        cta: {
          url: "/practice_implementation_checklist.pdf",
          ariaLabel: "Practice Implementation Checklist",
          label: "Download PDF",
          showFileIcon: true,
        },
      },

      {
        image: "CardA3",
        alt: "Practice Readiness Flashcard thumbnail",
        body: "Practice Readiness Flashcard",
        cta: {
          url: "/practice_readiness.pdf",
          ariaLabel: "Practice Readiness Flashcard",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA4",
        alt: "Coding and Billing Flashcard thumbnail",
        body: "Coding and Billing Flashcard",
        cta: {
          url: "/coding_billing.pdf",
          ariaLabel: "Coding and Billing Flashcard",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA6",
        alt: "Packaging and Ordering Overview Brochure thumbnail",
        body: "Packaging and Ordering Overview Brochure",
        cta: {
          url: "/packaging_ordering.pdf",
          ariaLabel: "Packaging and Ordering Overview Brochure",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA7",
        alt: "PALFORZIA REMS Program Overview Brochure thumbnail",
        body: "PALFORZIA REMS Program Overview Brochure",
        cta: {
          url: "/rems_brochure.pdf",
          ariaLabel: "PALFORZIA REMS Program Overview Brochure",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
      {
        image: "CardA8",
        alt: "Sample Letter of Medical Necessity thumbnail",
        body: "Sample Letter of Medical Necessity",
        cta: {
          url: "/sample_letter.pdf",
          ariaLabel: "Sample Letter of Medical Necessity",
          label: "Download PDF",
          showFileIcon: true,
        },
      },
    ],
  }

  const CARD_B = {
    header: "Educational support resources",
    cards: [
      {
        image: "CardB1",
        alt: "Dose Administration Overview Brochure thumbnail",
        body: "Dose Administration Overview Brochure",
        cta: {
          url: "/dose_admin.pdf",
          ariaLabel: "Dose Administration Overview Brochure",
          label: "Download PDF",
        },
      },
      {
        image: "CardB2",
        alt: "Treatment Pathway Brochure thumbnail",
        body: "Treatment Pathway Brochure",
        cta: {
          url: "/treatment_pathway.pdf",
          ariaLabel: "Treatment Pathway Brochure",
          label: "Download PDF",
        },
      },
      {
        image: "CardB3",
        alt: "Assessing Allergic Reactions Brochure thumbnail",
        body: "Assessing Allergic Reactions Brochure",
        cta: {
          url: "/allergic_reactions.pdf",
          ariaLabel: "Assessing Allergic Reactions Brochure",
          label: "Download PDF",
        },
      },
      {
        image: "CardB4",
        alt: "Dose Modifications and Missed Dose Management Brochure thumbnail",
        body: "Dose Modifications and Missed Dose Management Brochure",
        cta: {
          url: "/dose_modifications.pdf",
          ariaLabel: "Dose Modifications and Missed Dose Management Brochure",
          label: "Download PDF",
        },
      },
      {
        image: "CardB5",
        alt: "Dosing Overview Brochure thumbnail",
        body: "Dosing Overview Brochure",
        cta: {
          url: "/dosing_overview.pdf",
          ariaLabel: "Dosing Overview Brochure",
          label: "Download PDF",
        },
      },
      {
        image: "CardB6",
        alt: "Patient Dosing History Form thumbnail",
        body: "Patient Dosing History Form",
        cta: {
          url: "/patient_dosing_history.pdf",
          ariaLabel: "Patient Dosing History Form",
          label: "Download PDF",
        },
      },
    ],
  }

  return {
    header: { ...HEADER },
    tabs: [...TABS],
    topic: { ...TOPIC },
    topicB: { ...TOPIC_B },
    cardA: { ...CARD_A },
    cardB: { ...CARD_B },
  }
}
